var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('TitleItemDischarge',{attrs:{"borderBottom":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("print.discharge.optometristSummary")))])]),_c('OptometristBox',[_c('div',{staticClass:"optometristBox__content"},[_c('div',{staticClass:"optometristBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.chiefComplain"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !_vm.chiefComplain ? _vm.$t('print.discharge.noComplain') : _vm.chiefComplain
          )}})]),_c('div',{staticClass:"optometristBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.conclusion"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !_vm.conclusion ? _vm.$t('print.discharge.noConclusion') : _vm.conclusion
          )}})]),_c('div',{staticClass:"optometristBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.followUp"))+": ")]),(!_vm.dateFollowUp.length)?[_c('span',{staticClass:"item__content"},[_vm._v(_vm._s(_vm.$t("print.discharge.noFollowUp")))])]:[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.dateFollowUp),function(item,index){return _c('span',{key:index,staticClass:"item__content font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDate(item.timeStart))+" ")])}),0)]],2),_c('div',{staticClass:"optometristBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.image"))+": ")]),(!_vm.image)?[_c('span',{staticClass:"item__content"},[_vm._v(_vm._s(_vm.$t("print.discharge.noImage")))])]:[_c('vue-qrcode',{staticClass:"size-qr",attrs:{"value":_vm.image,"options":{ width: 60 }}})]],2)])]),_c('TitleItemDischarge',{attrs:{"borderBottom":false,"marginText":"0 0 0 20px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("print.discharge.glassPrescription")))])]),_c('GlassPrescription',{staticClass:"fit-content",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-block"})]},proxy:true}])}),_c('ExaminationTable',{ref:"OptometristExaminationTable",staticClass:"fit-content"}),_c('MedicineTable',{ref:"OptometristMedication",staticClass:"fit-content"}),_c('TitleItemDischarge',{attrs:{"borderBottom":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("print.discharge.doctorSummary")))])]),_c('DoctorBox',[_c('div',{staticClass:"doctorBox__content"},[_c('div',{staticClass:"doctorBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.chiefComplain"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !_vm.chiefComplain ? _vm.$t('print.discharge.noComplain') : _vm.chiefComplain
          )}})])]),_c('Diagnosis'),_c('AxPx'),_c('div',{staticClass:"doctorBox__content"},[_c('div',{staticClass:"doctorBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.dischargeMode"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !_vm.dischargeMode
              ? _vm.$t('print.discharge.noDischargeMode')
              : _vm.dischargeMode
          )}})]),_c('div',{staticClass:"doctorBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.patientSatisfaction"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !_vm.patientSatisfaction
              ? _vm.$t('print.discharge.noPatientSatisfaction')
              : _vm.patientSatisfaction
          )}})]),_c('div',{staticClass:"doctorBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.followUp"))+": ")]),(!_vm.dateFollowUp.length)?[_c('span',{staticClass:"item__content"},[_vm._v(_vm._s(_vm.$t("print.discharge.noFollowUp")))])]:[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.dateFollowUp),function(item,index){return _c('span',{key:index,staticClass:"item__content font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDate(item.timeStart))+" ")])}),0)]],2),_c('div',{staticClass:"doctorBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.image"))+": ")]),(!_vm.image)?[_c('span',{staticClass:"item__content"},[_vm._v(_vm._s(_vm.$t("print.discharge.noImage")))])]:[_c('vue-qrcode',{staticClass:"size-qr",attrs:{"value":_vm.image,"options":{ width: 60 }}})]],2)])],1),_c('ExaminationTable',{ref:"DoctorExaminationTable",staticClass:"fit-content"}),_c('MedicineTable',{ref:"DoctorMedication",staticClass:"fit-content"}),_c('ServiceTable',{ref:"ServiceTable",staticClass:"fit-content"}),_c('TitleItemDischarge',{attrs:{"borderBottom":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("print.discharge.referral")))])]),_c('ReferralBox',[_c('div',{staticClass:"referralBox__content"},[_c('div',{staticClass:"referralBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.referralLetter.clinic"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !this.referral.clinicName
              ? _vm.$t('print.discharge.referralLetter.noClinic')
              : this.referral.clinicName
          )}})]),_c('div',{staticClass:"referralBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.referralLetter.doctor"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !this.referral.doctorName
              ? _vm.$t('print.discharge.referralLetter.noDoctor')
              : this.referral.doctorName
          )}})]),_c('div',{staticClass:"referralBox__content__item"},[_c('span',{staticClass:"item__title"},[_vm._v(_vm._s(_vm.$t("print.discharge.referralLetter.note"))+": ")]),_c('span',{staticClass:"item__content",domProps:{"innerHTML":_vm._s(
            !this.referral.codeDescription
              ? _vm.$t('print.discharge.referralLetter.noDescription')
              : this.referral.codeDescription
          )}})])])]),_c('TableContent',{staticClass:"mt-5 mb-10"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t("print.discharge.service")))]),_c('th',[_vm._v("Procedure/Accessment")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("print.discharge.referralOrder")))]),_vm._l((_vm.typeOrder),function(type){return _c('td',{key:type,staticClass:"content__prescription"},_vm._l((_vm.orderCollections[("" + type)]),function(item){return _c('span',{key:item},[_vm._v(_vm._s(item.name)+" ,"),_c('br')])}),0)})],2)])]),_c('Footer',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"qrcode-discharge"},[_c('span',[_vm._v(_vm._s(_vm.$t("print.discharge.toView")))]),_c('div',{staticClass:"d-block w-100"},[_c('vue-qrcode',{staticClass:"size-qr",attrs:{"value":_vm.dischargeLink,"options":{ width: 100 }}})],1)])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"footer__staffHandler"},[_c('span',{staticClass:"footer__staffHandler__date"},[_vm._v(" "+_vm._s(_vm.$t("print.discharge.date"))+": "),_c('b',{staticClass:"bold__case"},[_vm._v(_vm._s(_vm.today))])]),_c('span',{staticClass:"footer__staffHandler__role"},[_vm._v(_vm._s(_vm.$t("print.discharge.approver")))]),_c('div',{staticClass:"footer__staffHandler__signal"}),_c('span',{staticClass:"footer__staffHandler__name"},[_vm._v(" "+_vm._s(_vm.userName)+" ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }