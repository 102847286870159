<template>
  <Container>
    <!-- Content -->
    <!-- OPTOMETRIST ELEMENT -->
    <TitleItemDischarge borderBottom>
      <span>{{ $t("print.discharge.optometristSummary") }}</span>
    </TitleItemDischarge>

    <OptometristBox>
      <div class="optometristBox__content">
        <div class="optometristBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.chiefComplain") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !chiefComplain ? $t('print.discharge.noComplain') : chiefComplain
            "
          ></span>
        </div>
        <div class="optometristBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.conclusion") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !conclusion ? $t('print.discharge.noConclusion') : conclusion
            "
          ></span>
        </div>
        <div class="optometristBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.followUp") }}:
          </span>
          <template v-if="!dateFollowUp.length">
            <span class="item__content">{{
              $t("print.discharge.noFollowUp")
            }}</span>
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <span
                class="item__content font-weight-bold"
                v-for="(item, index) in dateFollowUp"
                :key="index"
              >
                {{ formatDate(item.timeStart) }}
              </span>
            </div>
          </template>
        </div>
        <div class="optometristBox__content__item">
          <span class="item__title">{{ $t("print.discharge.image") }}: </span>
          <template v-if="!image">
            <span class="item__content">{{
              $t("print.discharge.noImage")
            }}</span>
          </template>
          <template v-else>
            <vue-qrcode
              :value="image"
              :options="{ width: 60 }"
              class="size-qr"
            ></vue-qrcode>
          </template>
        </div>
      </div>
    </OptometristBox>

    <TitleItemDischarge :borderBottom="false" marginText="0 0 0 20px">
      <span> {{ $t("print.discharge.glassPrescription") }}</span>
    </TitleItemDischarge>

    <GlassPrescription class="fit-content">
      <template #footer>
        <div class="d-block"></div>
      </template>
    </GlassPrescription>

    <ExaminationTable class="fit-content" ref="OptometristExaminationTable" />

    <MedicineTable class="fit-content" ref="OptometristMedication" />

    <!-- DOCTOR ELEMENT -->
    <TitleItemDischarge borderBottom>
      <span>{{ $t("print.discharge.doctorSummary") }}</span>
    </TitleItemDischarge>

    <DoctorBox>
      <div class="doctorBox__content">
        <div class="doctorBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.chiefComplain") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !chiefComplain ? $t('print.discharge.noComplain') : chiefComplain
            "
          ></span>
        </div>
      </div>
      <Diagnosis />
      <AxPx />
      <div class="doctorBox__content">
        <!-- <div class="doctorBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.diagnosis") }}:
          </span>
          <template v-if="!diagnosis.length">
            <span class="item__content">{{
              $t("print.discharge.noDiagnosis")
            }}</span>
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <span
                class="item__content font-weight-bold"
                v-for="(item, index) in diagnosis"
                :key="index"
              >
                {{ getCodePositionName(item.diagnosisCodePosition) }} -
                {{ item.icdDxCode }} - {{ item.icdDxDescription }}
              </span>
            </div>
          </template>
        </div> -->
        <!-- <div class="doctorBox__content__item">
          <span class="item__title">Procedure / Accessment: </span>
          <template v-if="!procedureAccessment.length">
            <span class="item__content">{{
              $t("print.discharge.noAxPx")
            }}</span>
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <span
                class="item__content font-weight-bold"
                v-for="(item, index) in procedureAccessment"
                :key="index"
              >
                {{ getCodePositionName(item.treatmentCodePosition) }} -
                {{ item.icdCode }} - {{ item.icdDescription }}
              </span>
            </div>
          </template>
        </div> -->
        <div class="doctorBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.dischargeMode") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !dischargeMode
                ? $t('print.discharge.noDischargeMode')
                : dischargeMode
            "
          ></span>
        </div>
        <div class="doctorBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.patientSatisfaction") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !patientSatisfaction
                ? $t('print.discharge.noPatientSatisfaction')
                : patientSatisfaction
            "
          ></span>
        </div>
        <div class="doctorBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.followUp") }}:
          </span>
          <template v-if="!dateFollowUp.length">
            <span class="item__content">{{
              $t("print.discharge.noFollowUp")
            }}</span>
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <span
                class="item__content font-weight-bold"
                v-for="(item, index) in dateFollowUp"
                :key="index"
              >
                {{ formatDate(item.timeStart) }}
              </span>
            </div>
          </template>
        </div>
        <div class="doctorBox__content__item">
          <span class="item__title">{{ $t("print.discharge.image") }}: </span>
          <template v-if="!image">
            <span class="item__content">{{
              $t("print.discharge.noImage")
            }}</span>
          </template>
          <template v-else>
            <vue-qrcode
              :value="image"
              :options="{ width: 60 }"
              class="size-qr"
            ></vue-qrcode>
          </template>
        </div>
      </div>
    </DoctorBox>

    <ExaminationTable class="fit-content" ref="DoctorExaminationTable" />

    <MedicineTable class="fit-content" ref="DoctorMedication" />

    <ServiceTable class="fit-content" ref="ServiceTable" />

    <!-- REFERRAL ITEM -->
    <TitleItemDischarge borderBottom>
      <span>{{ $t("print.discharge.referral") }}</span>
    </TitleItemDischarge>
    <ReferralBox>
      <div class="referralBox__content">
        <div class="referralBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.referralLetter.clinic") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !this.referral.clinicName
                ? $t('print.discharge.referralLetter.noClinic')
                : this.referral.clinicName
            "
          ></span>
        </div>
        <div class="referralBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.referralLetter.doctor") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !this.referral.doctorName
                ? $t('print.discharge.referralLetter.noDoctor')
                : this.referral.doctorName
            "
          ></span>
        </div>
        <div class="referralBox__content__item">
          <span class="item__title"
            >{{ $t("print.discharge.referralLetter.note") }}:
          </span>
          <span
            class="item__content"
            v-html="
              !this.referral.codeDescription
                ? $t('print.discharge.referralLetter.noDescription')
                : this.referral.codeDescription
            "
          ></span>
        </div>
      </div>
    </ReferralBox>

    <TableContent class="mt-5 mb-10">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("print.discharge.service") }}</th>
          <th>Procedure/Accessment</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{{ $t("print.discharge.referralOrder") }}</th>
          <td
            v-for="type in typeOrder"
            :key="type"
            class="content__prescription"
          >
            <span v-for="item in orderCollections[`${type}`]" v-bind:key="item"
              >{{ item.name }} ,<br
            /></span>
          </td>
        </tr>
      </tbody>
    </TableContent>

    <!-- table content -->
    <Footer>
      <v-row justify="space-between">
        <v-col cols="6">
          <div class="qrcode-discharge">
            <span>{{ $t("print.discharge.toView") }}</span>
            <div class="d-block w-100">
              <vue-qrcode
                :value="dischargeLink"
                :options="{ width: 100 }"
                class="size-qr"
              ></vue-qrcode>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="footer__staffHandler">
            <span class="footer__staffHandler__date">
              {{ $t("print.discharge.date") }}:
              <b class="bold__case">{{ today }}</b>
            </span>
            <span class="footer__staffHandler__role">{{
              $t("print.discharge.approver")
            }}</span>
            <div class="footer__staffHandler__signal"></div>
            <span class="footer__staffHandler__name">
              {{ userName }}
            </span>
          </div>
        </v-col>
      </v-row>
    </Footer>
  </Container>
</template>

<script>
import moment from "moment";
import {
  Container,
  OptometristBox,
  DoctorBox,
  ReferralBox,
  Footer,
  TableContent,
  TitleItemDischarge,
} from "./css/style";
import { UserTypeCodes, ConclusionType } from "@/plugins/constant";
import { awaitAll } from "@/plugins/helper";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import PaymentService from "@/services/payment";

//Service
import WorkListService from "@/services/work-list";
import BookingService from "@/services/booking";
//import TreatmentService from "@/services/treatment";
//import DiagnosisService from "@/services/diagnosis";
import DataPointService from "@/services/dataPoint";
import DischargeService from "@/services/discharge";
import MedicationService from "@/services/medication";
import ConclusionService from "@/services/conclusion";
import ReferralService from "@/services/referral";

//Component
import GlassPrescription from "@/components/prescription/GlassPrint";
//import GlassPrescription from "@/components/common/dischargeSummary/components/Print/GlassPrescription";
import MedicineTable from "@/components/common/dischargeSummary/components/Print/Medicine";
import ExaminationTable from "@/components/common/dischargeSummary/components/Print/ExaminationTable";
import ServiceTable from "@/components/common/dischargeSummary/components/Print/Service";
import Diagnosis from "@/components/common/dischargeSummary/components/Diagnosis";
import AxPx from "@/components/common/dischargeSummary/components/AxPx";

export default {
  name: "DischargeSummary",
  props: {
    // print: {
    //   type: Boolean,
    //   default: false,
    // },
    detail: {
      type: Boolean,
    },
  },
  components: {
    Container,
    OptometristBox,
    DoctorBox,
    ReferralBox,
    GlassPrescription,
    MedicineTable,
    ExaminationTable,
    ServiceTable,
    TableContent,
    VueQrcode,
    Footer,
    TitleItemDischarge,
    Diagnosis,
    AxPx,
  },
  computed: {
    today() {
      return moment().format("DD-MM-YYYY");
    },
    userName() {
      let user = this.$store.getters.userData;
      return user.fullName;
    },
    dilation() {
      return this.ou.dlc != "" ? `(Sau liệt DT - ${this.ou.dlc})` : "";
    },
  },
  data: () => ({
    typeOrder: {
      Service: 1,
      AxPx: 2,
    },
    referral: {},
    orderCollections: [],
    dischargeLink: "",
    chiefComplain: "",
    diagnosis: "",
    dateFollowUp: "",
    conclusion: "",
    procedureAccessment: "",
    dischargeMode: "",
    patientSatisfaction: "",
    image: "",
    od: {
      subjective: {
        rx: "",
        refractiveError: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },

      tonometer: {
        iop: "",
      },
      currentGlass: {
        progressiveGlass: {
          rx: "",
          va: "",
        },
        nearGlass: {
          rx: "",
        },
        distantGlass: {
          rx: "",
          va: "",
        },
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
    os: {
      subjective: {
        rx: "",
        refractiveError: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },
      newGlass: {
        rx: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
        note: "",
        newTypeGlass: "",
        bcva: "",
      },
      tonometer: {
        iop: "",
      },
      currentGlass: {
        progressiveGlass: {
          rx: "",
          va: "",
        },
        nearGlass: {
          rx: "",
        },
        distantGlass: {
          rx: "",
          va: "",
        },
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
    ou: {
      pd: "",
      subjective: {
        bcva: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },
      newGlass: {
        rx: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
        note: "",
        newTypeGlass: "",
        bcva: "",
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
  }),
  async created() {},
  mounted() {
    this.printDischarge();
  },
  methods: {
    showError(message) {
      // this.$toast.error(message);
      console.error(message);
    },
    async printDischarge() {
      await awaitAll([
        this.$emit("change-detail", { detail: this.detail }),
        this.renderChiefComplainAndListExams(),
        //this.renderDiagnosis(),
        this.renderFollowUp(),
        this.renderImageLink(),
        this.renderDischargeDetail(),
        this.renderMedications(),
        this.renderPaymentDetail(),
        this.renderDischargeLink(),
        this.renderReferralDetail(),
        this.renderConclusionDetail(),
      ]);
      setTimeout(() => {
        window.print();
        // setTimeout(() => {
        //   window.close();
        // }, 500);
      }, 500);
    },
    renderDischargeLink() {
      let patientID = this.$route.params.patientId;
      let episodeRecordID = this.$route.params.episodeRecordID;
      let viewUrl = encodeURI(
        window.location.protocol +
          "//" +
          window.location.host +
          `/patient/${patientID}/episodeRecord/${episodeRecordID}/discharge-summary`
      );
      this.dischargeLink = `${window.location.protocol}//${window.location.host}/scannedQR?viewUrl=${viewUrl}`;
    },
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(date) {
      if (!date) return "";
      return `${this.jsUcfirst(
        moment(date).lang("vi").format("dddd")
      )}, Ngày ${moment(date).format("DD/MM/YYYY")} lúc ${moment(date).format(
        "HH:mm"
      )}`;
    },
    getCodePositionName(code) {
      return code == 1 ? "Mắt phải" : code == 2 ? "Mắt trái" : "Hai mắt";
    },
    async renderChiefComplainAndListExams() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (result.error) {
        this.showError("Cannot get work list info! Please try again later!");
        return;
      }
      var { orders, admissionInfo } = result || {};
      if (admissionInfo.chiefComplaints) {
        this.chiefComplain = admissionInfo.chiefComplaints;
      }
      var items = JSON.parse(JSON.stringify(orders || []));

      // map path for each order
      items.forEach((item) => {
        item.path = `examination?orderID=${item.orderID}&formID=${item.formID}&targetID=${item.targetID}`;
      });
      // find order performed by optomologist
      this.$refs.OptometristExaminationTable.loadExaminations(
        items.filter((i) =>
          i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
        )
      );
      // find order performed by doctor
      this.$refs.DoctorExaminationTable.loadExaminations(
        items.filter(
          (i) => !i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
        )
      );
    },
    async renderMedications() {
      const episodeRecordID = this.$route.params.episodeRecordID;
      var result = await MedicationService.search(episodeRecordID, "", 1, 1000);
      if (!result || result.error) {
        this.showError("Cannot get list medicines. Please try again later!");
        return;
      }
      // load medication optometrists
      this.$refs.OptometristMedication.loadMedications(
        result.items.filter(
          (i) => i.userPrescribeRoleID == UserTypeCodes.Optomologist
        )
      );
      // load medication doctors
      this.$refs.DoctorMedication.loadMedications(
        result.items.filter(
          (i) => i.userPrescribeRoleID != UserTypeCodes.Optomologist
        )
      );
    },
    // async renderDiagnosis() {
    //   const apiResult = await DiagnosisService.SearchListDiagnosis(
    //     this.$route.params.episodeRecordID,
    //     1,
    //     100
    //   );
    //   if (!apiResult || apiResult.error) {
    //     this.showError(
    //       "Cannot get Diagnosis at the moment! Please try again later."
    //     );
    //     return;
    //   }
    //   this.diagnosis = apiResult.items;
    // },
    // async renderTreatment() {
    //   const apiResult = await TreatmentService.SearchListTreatment(
    //     this.$route.params.episodeRecordID,
    //     1,
    //     100
    //   );
    //   if (!apiResult || apiResult.error) {
    //     this.showError(
    //       "Cannot get Procedure/Accessment at the moment! Please try again later."
    //     );
    //     return;
    //   }
    //   this.procedureAccessment = apiResult.items;
    // },
    async renderFollowUp() {
      var apiResult = await BookingService.getBookingByPatient(
        this.$route.params.patientId,
        moment().format("YYYY-MM-DD"),
        ""
      );
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get follow up at the moment! Please try again later."
        );
        return;
      }
      this.dateFollowUp = apiResult.items;
    },
    async renderImageLink() {
      const apiResult = await DataPointService.searchDataPoint(
        this.$route.params.episodeRecordID,
        "IMGL",
        1,
        100
      );
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get image link at the moment! Please try again later."
        );
        return;
      }
      this.image = apiResult.items[0] && apiResult.items[0].value;
    },
    async renderDischargeDetail() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await DischargeService.getDischargeDetail(episodeRecordID);
      if (!result || result.error) {
        this.showError("Cannot get discharge detail. Please try again later!");
        return;
      }
      this.dischargeMode = result.dischargeModeName;
      this.patientSatisfaction = result.patientSatisfactionName;
    },
    async renderPaymentDetail() {
      var result = await PaymentService.getPaymentByEpisodeRecordID(
        this.$route.params.episodeRecordID
      );
      if (result.error) {
        this.showError("Cannot get payment summary. Please try again later!");
        return;
      }
      this.$refs.ServiceTable.loadServices(result.items);
    },
    async renderConclusionDetail() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await ConclusionService.getConclusionDetail(episodeRecordID);
      if (!result) return;
      var { conclusionType, conclusionSuggestion } = result;
      if (conclusionType == ConclusionType.Normal) {
        this.conclusion =
          "Bệnh nhân thực hiện theo chỉ định của đơn kính và đơn thuốc. Tái khám theo lịch đã hẹn (nếu có).";
        return;
      }
      if (conclusionType == ConclusionType.Symptoms) {
        conclusionSuggestion = conclusionSuggestion || [];
        var concludeStr = `Bệnh nhân này nghi ngờ bị `;
        var symptoms = !conclusionSuggestion.length
          ? "bệnh"
          : conclusionSuggestion
              .filter((i) => i.codeDescription)
              .map(
                (i) =>
                  `${i.codeDescription} ở ${this.getCodePositionName(
                    i.conclusionCodePosition
                  )}`
              )
              .join(" và ");
        concludeStr += symptoms;
        concludeStr += ". Xin chuyển bác sĩ theo dõi tiếp .";
        this.conclusion = concludeStr;
        return;
      }
      this.conclusion = "";
    },
    async renderReferralDetail() {
      var result = await ReferralService.getReferral(
        this.$route.params.episodeRecordID
      );
      if (result.error) {
        this.showError("Cannot get referral. Please try again later!");
        return;
      }
      this.referral = result;
      var items = result.referralItems.map((i) => ({
        ...i,
        tempAxpxType: i.typeID == 3 ? 1 : 2,
        name: i.axPxName ?? i.serviceName,
      }));
      for (var type in this.typeOrder) {
        this.orderCollections[`${this.typeOrder[type]}`] = items.filter(
          (i) => i.tempAxpxType == this.typeOrder[type]
        );
      }
    },
  },
};
</script>

<style>
.fit-content {
  margin: 10px 0;
}
.border-bottom {
  border-bottom: 1px solid;
  padding-bottom: 1px;
  font-size: 20px;
}
@media print {
  .size-qr {
    width: 50px;
  }
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  .text-right {
    padding-right: 10px;
    font-weight: 450;
  }
  .text-left {
    width: 100%;
    display: inline-block;

    padding-bottom: 2px;
  }
  .order {
    font-size: 12px;
  }
  .name {
    font-size: 12px;
  }
  .unit {
    font-size: 12px;
  }
  td {
    padding: 1px;
    font-size: 10px;
  }
  .footer__content {
    margin-top: 3px;
  }
  .qrcode-discharge {
    text-align: center;
    float: left;
  }
}
</style>