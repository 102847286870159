<template>
  <div>
    <TitleItemDischarge :borderBottom="false" marginText="0 0 0 20px">
      <span>{{ $t("print.discharge.medicine") }}</span>
    </TitleItemDischarge>
    <TableContent>
      <thead>
        <tr>
          <th colspan="6">{{ $t("examination.dischargeSummary.medName") }}</th>
          <th colspan="2">
            {{ $t("examination.dischargeSummary.intruction") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="idx">
          <td colspan="6" class="content__prescription">
            <span>{{ item.medicineName || "N/A" }}</span>
          </td>
          <td colspan="2" class="content__prescription">
            <span>{{ item.note || "N/A" }}</span>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="8" class="content__prescription">
            <span>{{ $t("examination.dischargeSummary.noMedicine") }}.</span>
          </td>
        </tr>
      </tbody>
    </TableContent>
  </div>
</template>

<script>
import {
  TableContent,
  TitleItemDischarge,
} from "@/components/prescription/css/style";

export default {
  name: "ServicePrint",
  props: {
    print: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Boolean,
    },
  },
  components: {
    TableContent,
    TitleItemDischarge,
  },
  computed: {},
  data: () => ({ items: [] }),
  created() {
    this.$emit("change-detail", { detail: this.detail });
  },
  mounted() {},
  methods: {
    loadMedications(items) {
      this.items = items;
    },
  },
};
</script>

<style>
</style>